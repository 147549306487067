<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="evaluate" v-else>
      <b-container fluid>
        <br />
        <b-row>
          <b-col md="12">
            <div class="loading" v-if="StudentExamSheet">
              <load-data></load-data>
            </div>
            <div v-else>
              <!-- <div v-if="studentSheet.length != 0"> -->
              <div>
                <div class="closeBtn mb-2 text-right">
                  <b-button variant="danger" size="sm" @click="closeCurrentTab()">Close</b-button>
                </div>
                <h4 class="text-center" style="font-size: 24px; font-weight: 900">
                  Evaluation Sheet for: <span class="text-info">{{ exam.name }}</span>
                  <br>
                  <div v-if="scoreNotUpdated" class="text-danger">Exam score is not updated yet, please check back
                    later.</div>
                  <span v-if="totalScore"> Total score : {{ totalScore }}/{{ maxScore }}</span>
                  <br>
                  <span v-if="totalScore"> {{ (Math.floor(totalScore * 10000 / maxScore) / 100) }} %</span>
                </h4>

                <div class="studentSheet mt-3" v-for="(sheet, sheetIndex) in studentSheet" :key="sheetIndex">
                  <b-card header-tag="header" class="mt-3" footer-tag="footer">
                    <template #header>
                      <div class="question">
                        <p>Ques: {{ sheet.question }} <span v-if="(sheet.maximum_mark > 0)">(Score:
                            {{ sheet.maximum_mark }})</span></p>
                      </div>
                      <span class="text-secondary" v-if="sheet.orquestion_group_id">
                        {{ orquestiongroups[sheet.orquestion_group_id].summary }}: Answer any {{
                            orquestiongroups[sheet.orquestion_group_id].best_of_questions
                        }}
                        (Score: {{ orquestiongroups[sheet.orquestion_group_id].maximum_mark_of_question }})
                      </span>
                    </template>
                    <b-card-body>
                      <div class="answer d-flex justify-content-between align-items-baseline">
                        <div class="ans">
                          <p v-if="(sheet.answer != null)">Ans: {{ sheet.answer }}</p>
                          <p v-if="(sheet.choice_id)">Choice: <span
                              :style="sheet.choices[sheet.choice_id].is_right_choice == 1 ? 'color:green;' : 'color:red;'">
                              {{ sheet.choices[sheet.choice_id].name }}</span></p>
                        </div>
                        <div class="marks">
                          <p>{{ sheet.marks }}</p>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="questionAttachments" v-if="sheet.files.length != 0">
                          <p>Question Attachments:</p>
                          <div class="attachments" v-for="(file, fileIndex) in sheet.files" :key="fileIndex">
                            <a :href="userAttachments + file.file_path + file.file_name" class="text-decoration-none"
                              target="_blank" style="font-size: 16px">
                              {{ file.file_name }}
                            </a>
                          </div>
                        </div>
                        <div class="answerAttachments" v-if="sheet.studentFiles.length != 0">
                          <p>Answers Attachments:</p>
                          <div class="attachments" v-for="(file, fileIndex) in sheet.studentFiles" :key="fileIndex">
                            <a :href="userAttachments + file.file_path + file.file_name" class="text-decoration-none"
                              target="_blank" style="font-size: 16px">
                              {{ file.file_name }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </div>
              </div>
              <!-- <div class="markingNotDoneYet" v-else>
                <br />
                <h4 class="text-danger text-center">Marks not alloted yet!</h4>
              </div> -->
            </div>

            <div v-if="enableCoScoreView">
              <br><br>
              <div> </div>
              <table class="coScore-table">
                <thead>
                  <tr>
                    <th>CO</th>
                    <th>Description</th>
                    <th>Score for CO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(co, coIndex) in courseOutcomes" :key="coIndex">
                    <th>
                      {{ co.code }}
                    </th>
                    <td>{{ co.outcome }}</td>
                    <td
                      v-if="(participant.courseoutcomes_score.hasOwnProperty(co.id) && exam.max_co_scores.hasOwnProperty(co.id))">
                      {{ participant.courseoutcomes_score[co.id] }}/{{ exam.max_co_scores[co.id] }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td colspan="2">Total</td>
                    <td>{{ totalCoScore }}/{{ maxCoScore }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">%</td>
                    <td>{{ (Math.round(totalCoScore * 1000 / maxCoScore) / 10) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.fetchData = true;
    this.getExam();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      fetchData: false,
      StudentExamSheet: false,
      totalScore: 0,
      maxScore: 0,
      userAttachments: "",
      currentMark: "0",
      exam: {},
      students: [],
      examQuestions: [],
      orquestiongroups: {},
      orGroupScores: {},
      studentSheet: [],
      marksBtn: {
        text: "Submit",
        disabled: false,
      },
      enableCoScoreView: false,
      courseOutcomes: [],
      participant: null,
      totalCoScore: 0,
      maxCoScore: 0,
      scoreNotUpdated: true,
    };
  },
  methods: {
    getExam() {
      const examId = this.$route.params.exam_id;
      const url = this.$store.getters.getAPIKey.examination + `/${examId}`;

      axios
        .get(url)
        .then((response) => {
          this.exam = {};
          this.exam = response.data;
          this.getExamQuestions();
          //   this.getCourse();
          response;
        })
        .catch((error) => {
          error;
        });
    },

    async getCourseOutcomes() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.exam.course[0].id}/courseoutcomes`;
      await axios
        .get(url)
        .then((response) => {
          this.courseOutcomes = response.data;
          this.courseOutcomes.forEach(co => {
            this.totalCoScore += Number(this.participant.courseoutcomes_score[co.id]);
            this.maxCoScore += Number(this.exam.max_co_scores[co.id]);
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getParticipantCoScore() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam.id}/examparticipant`;
      await axios
        .get(url)
        .then((response) => {
          this.participant = response.data;
        })
        .catch((error) => {
          error;
        });
      if (this.participant && this.participant.courseoutcomes_score) {
        await this.getCourseOutcomes();
        this.enableCoScoreView = true;
      }
    },

    async getOrquestionGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examorquestiongroups?exam_id=${this.exam.id}`;
      let orquestiongroups = await this.$axios.get(url).then((response) => {
        return response.data;
      });
      this.orquestiongroups = {};
      // this.orGroupQuestionIds = {}; // initiate
      await orquestiongroups.forEach((group) => {
        // this.orGroupQuestionIds[group.id] = [];
        this.orquestiongroups[group.id] = group;
        this.orGroupScores[group.id] = [];
      });

    },

    getStudentSheet() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam.id}/institutionuser/examanswers`;
      axios
        .get(url)
        .then((response) => {
          let answers = response.data;
          if (answers.length != 0) {
            answers.forEach((answer) => {
              let quesId = answer.examquestion_id;
              this.studentSheet.forEach((checkAns) => {
                if (quesId == checkAns.questionId) {
                  checkAns.answer = answer.answer;
                  checkAns.studentId = answer.student_id;
                  checkAns.choice_id = answer.choice_id;
                  checkAns.studentFiles = answer.files;
                  if (answer.score != null) {
                    this.scoreNotUpdated = false;
                    let marks = answer.score;
                    checkAns.marks =
                      marks != null
                        ? marks % 1 == 0
                          ? Math.floor(marks)
                          : parseFloat(marks).toFixed(2)
                        : null;
                    // checkAns.marks = Math.floor(answer.score);
                    if (checkAns.orquestion_group_id) {
                      this.orGroupScores[checkAns.orquestion_group_id].push(parseFloat(answer.score));
                    } else {
                      this.totalScore = this.totalScore + parseFloat(answer.score);
                    }
                  }
                }
              });
            });

            if (Object.keys(this.orGroupScores).length > 0) {
              for (const key in this.orGroupScores) {
                let scores = this.orGroupScores[key].sort(function (a, b) { return b - a; });
                let orgroup = this.orquestiongroups[key];
                for (let index = 0; index < Number(orgroup.best_of_questions); index++) {
                  this.totalScore += scores[index];
                }
              }
            }
          }
          if (this.exam.max_co_scores != null) {
            this.getParticipantCoScore();
          }
          this.StudentExamSheet = false;
          this.fetchData = false;
          // if (this.studentSheet.length == 0) {
          //   setTimeout(() => {
          //     this.closeCurrentTab();
          //   }, 5000);
          // }

        })
        .catch((error) => {
          error;
        });
    },
    async getExamQuestions() {
      this.StudentExamSheet = true;
      await this.getOrquestionGroups();
      const url =
        this.$store.getters.getAPIKey.examination + `/${this.exam.id}/examquestions`;
      await axios
        .get(url)
        .then((response) => {
          this.examQuestions = [];
          this.studentSheet = [];
          this.examQuestions = response.data;
          if (this.examQuestions.length != 0) {
            // this.answers = [];
            let orQgroupIds = [];
            this.examQuestions.forEach((ques) => {
              let qChoices = {};
              if (ques.choices.length > 0) ques.choices.forEach(choice => {
                qChoices[choice.id] = choice;
              });
              this.studentSheet.push({
                questionId: ques.id,
                studentId: "",
                questionNumber: ques.question_no,
                question: ques.name,
                maximum_mark: ques.maximum_mark,
                answer: "",
                marks: "",
                attachment: [],
                files: ques.files,
                studentFiles: [],
                orquestion_group_id: ques.orquestion_group_id,
                choices: qChoices,
                choice_id: null
              });
              if (ques.orquestion_group_id != null) {
                if (!orQgroupIds.includes(ques.orquestion_group_id)) {
                  orQgroupIds.push(ques.orquestion_group_id);
                  this.maxScore += Number(this.orquestiongroups[ques.orquestion_group_id].maximum_mark_of_question);
                }
              } else {
                this.maxScore += Number(ques.maximum_mark);
              }
            });
          }
          response;
        })
        .catch((error) => {
          error;
        });
      this.getStudentSheet();
    },
    clearResponse() {
      this.marksBtn.text = "Submit";
      setTimeout(() => {
        this.getExamQuestions();
        this.marksBtn.disabled = false;
      }, 3000);
    },
  },
  watch: {
    studentSheet: {
      deep: true,
      handler: async function () {
        let answerMarks;
        // if (this.studentSheet.length != 0) {
        await this.studentSheet.forEach((mark) => {
          // if (mark.marks == "") {
          //   this.currentMark = "0";
          // }
          if (mark.marks != "") {
            answerMarks = mark.marks;
          }
        });
        if (answerMarks != undefined) {
          this.currentMark = this.currentMark + answerMarks;
        }

        // }
      },
    },
  },
};
</script>

<style >
.evaluate .col-md-12 {
  height: 600px;
  overflow-y: scroll;
}

.coScore-table {
  width: 100%;
}

.coScore-table table,
th,
td {
  border: 1px solid;
}
</style>
